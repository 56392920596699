code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Calendar 100% height */
.calendar { height: 100%; 
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}
/* Above only works if child weeks aren't displayed as flex... */
/* Uncomment to see what I mean */


.th {
    flex: 30px 0 0;
} 
.week {
    flex: 30px 1 0;
    border-bottom: 1px solid #ccc;    
}

/* Calendar rows */
.th, .week { display: flex; }
.th>span {
    border-bottom: 4px solid rgba(255,0,0,0.3);
    padding: 0 2px 2px 0;
    text-align: right;
}
.week>div {
    border-top: 1px solid #eee;
    padding: 20px 2px 2px;
    min-height: 50px;
    position: relative; /* allow ::after psuedo element absolute positioning */
}
.th>span, .week>div {
    width: 100%;
    margin-right: 5px;
    color: #333;
}
/* Dates */
.week>div::after {
    content: attr(data-date);
    display: block;
    position: absolute;
    top: 0; right: 2px;
    color: #999;
}

.topODay {
  position: absolute;
  display: block;
  bottom: 0; right: 2px;
}

/* Weekends */
/* .th>span:first-child, .th>span:last-child, .week>div:first-child, .week>div:last-child {background:#f3f3f3} */
.th>span:last-child, .week>div:last-child {margin-right:0}

.winter {
  background: #E1F5FE;
}

.summer {
  background: #FCE4EC;
}

.spring {
  background: #E8F5E9;
}

.fall {
  background: #FFF8E1;
}